import { SelectListVm } from "../SelectListVm";
export class LegalEntityVm {
    id;
    name;
    brandName;
    productCompanyName;
    machineCategoryName;
    constructor({ id, name, brandName, productCompanyName, machineCategoryName, }) {
        this.id = id;
        this.name = name;
        this.brandName = brandName;
        this.productCompanyName = productCompanyName;
        this.machineCategoryName = machineCategoryName;
    }
}
export class LegalEntityAddOrEditVm {
    id;
    name;
    brandId;
    _brand;
    get brand() {
        return this._brand;
    }
    set brand(v) {
        this._brand = v;
        this.brandId = v?.id;
    }
    productCompanyId;
    _productCompany;
    get productCompany() {
        return this._productCompany;
    }
    set productCompany(v) {
        this._productCompany = v;
        this.productCompanyId = v?.id;
    }
    machineCategoryId;
    _machineCategory;
    get machineCategory() {
        if (this._machineCategory === undefined) {
            return null;
        }
        return this._machineCategory;
    }
    set machineCategory(v) {
        this._machineCategory = v;
        this.machineCategoryId = v?.id;
    }
    constructor(legalEntity) {
        this.id = legalEntity.id;
        this.name = legalEntity.name;
        this.brand = SelectListVm.mapFromBrandVm(legalEntity.brand);
        this.productCompany = SelectListVm.mapFromProductCompanyVm(legalEntity.productCompany);
        this.machineCategory = SelectListVm.mapFromMachineCategoryVmForLegalEntity(legalEntity.machineCategory);
    }
}
