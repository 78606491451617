import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import TextInputWithValidation from "@/components/TextInputWithValidation.vue";
import Notifications from '@kyvg/vue3-notification';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import { BootstrapVueNext, vBToggle } from 'bootstrap-vue-next';
import ModalPlugin from 'bootstrap-vue-next';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import Vue3Lottie from 'vue3-lottie';
import { EventType } from "@azure/msal-browser";
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { CustomNavigationClient } from "./router/NavigationClient";
import { STORE_MODULE_NAMES } from "./store/types/store-module-names";
import { MUTATION_TYPES } from "./store/types/mutation-types";
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
    store.commit(`${STORE_MODULE_NAMES.USER}/${MUTATION_TYPES.SET_USER}`, msalInstance.getActiveAccount());
}
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
        store.commit(`${STORE_MODULE_NAMES.USER}/${MUTATION_TYPES.SET_USER}`, msalInstance.getActiveAccount());
    }
});
const app = createApp(App);
app.directive("b-toggle", vBToggle);
app.component("TextInputWithValidation", TextInputWithValidation);
app.use(store);
app.use(router);
app.use(msalPlugin, msalInstance);
app.use(Notifications);
app.use(BootstrapVueNext);
app.use(ModalPlugin);
app.use(BootstrapIconsPlugin);
app.use(Vue3Lottie);
router.isReady().then(() => app.mount('#app'));
