var _a, _b, _c, _d, _e, _f;
import { __decorate, __metadata } from "tslib";
import { ddpService } from "@/services/DdpService";
import { ACTION_TYPES } from "@/store/types/action-types";
import { Action, Module, VuexModule } from "vuex-module-decorators";
let Topics = class Topics extends VuexModule {
    async [_b = ACTION_TYPES.GET_TOPICS_BY_MODEL_ID](id) {
        const result = await ddpService.getTopicsByModelId(id);
        return result;
    }
    async [_d = ACTION_TYPES.GET_TOPICS_BY_MODEL_MODYEAR](query) {
        const result = await ddpService.getTopicsByModelModyear(query);
        return result;
    }
    async [_f = ACTION_TYPES.GET_TOPICS_BY_PUBLICATION_GUID](query) {
        const result = await ddpService.getTopicsByPublicationGuid(query);
        return result;
    }
};
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", typeof (_a = typeof Promise !== "undefined" && Promise) === "function" ? _a : Object)
], Topics.prototype, _b, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", typeof (_c = typeof Promise !== "undefined" && Promise) === "function" ? _c : Object)
], Topics.prototype, _d, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", typeof (_e = typeof Promise !== "undefined" && Promise) === "function" ? _e : Object)
], Topics.prototype, _f, null);
Topics = __decorate([
    Module({ namespaced: true })
], Topics);
export default Topics;
