import { isNullOrUndefined } from "@/common/Utils";
import { StatusCodes } from "@/common/types/StatusCodes";
class ApiHelper {
    static API_BASE_URL = process.env.VUE_APP_DDP_API_URL ? process.env.VUE_APP_DDP_API_URL : "";
    static BASE_PATH = "/api";
    createUrl(path, ...params) {
        let url = `${ApiHelper.API_BASE_URL}${ApiHelper.BASE_PATH}/${path}`;
        for (let i = 0; i < params.length; i++) {
            const param = params[i];
            if (param !== null) {
                url += `/${param}`;
            }
        }
        return url;
    }
}
export const apiHelper = new ApiHelper();
export class ActionResult {
    _isSuccess;
    _result;
    _errorModel;
    _status;
    constructor(isSuccess, result, errorModel, status) {
        this._isSuccess = isSuccess;
        this._result = isNullOrUndefined(result) ? null : result;
        this._errorModel = isNullOrUndefined(errorModel)
            ? null
            : errorModel;
        this._status = isNullOrUndefined(status) ? null : status;
    }
    get isSuccess() {
        return this._isSuccess;
    }
    get result() {
        return this._result;
    }
    get errorModel() {
        return this._errorModel;
    }
    get status() {
        return this._status;
    }
}
export class SuccessActionResult extends ActionResult {
    constructor(result) {
        super(true, result);
    }
}
export class FailActionResult extends ActionResult {
    constructor({ data, status }) {
        const errorModel = data;
        super(false, undefined, errorModel, status);
    }
    static GetDefaultFailActionResult() {
        return new FailActionResult({
            data: null,
            status: StatusCodes.InternalServerError
        });
    }
}
