var _a, _b, _c, _d, _e;
import { __decorate, __metadata } from "tslib";
import { ACTION_TYPES } from "@/store/types/action-types";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { ddpService } from "@/services/DdpService";
import { PublicationRequestVm } from "@/models/publicationRequest";
import { STORE_MODULE_NAMES } from "@/store/types/store-module-names";
import { FailActionResult } from "@/common/helpers/ApiHelper";
let User = class User extends VuexModule {
    publicationRequest = null;
    error = null;
    paginationActive = true;
    async [_a = ACTION_TYPES.SEARCH_PUBLICATION_REQUEST](guid) {
        if (!guid)
            return;
        this.context.commit(`${STORE_MODULE_NAMES.PUBLICATION_REQUEST}/${MUTATION_TYPES.SET_ERROR}`, null, {
            root: true
        });
        const result = await ddpService.getItemByIdString("publicationrequest", guid, STORE_MODULE_NAMES.PUBLICATION_REQUEST);
        if (result.isSuccess) {
            this.context.commit(MUTATION_TYPES.SET_PUBLICATION_REQUEST, result.result);
        }
        else {
            this.context.commit(MUTATION_TYPES.SET_PUBLICATION_REQUEST, null);
        }
    }
    [_c = MUTATION_TYPES.SET_PUBLICATION_REQUEST](data) {
        this.publicationRequest = data;
    }
    [_e = MUTATION_TYPES.SET_ERROR](error) {
        this.error = error;
    }
};
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], User.prototype, _a, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof PublicationRequestVm !== "undefined" && PublicationRequestVm) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], User.prototype, _c, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof FailActionResult !== "undefined" && FailActionResult) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], User.prototype, _e, null);
User = __decorate([
    Module({ namespaced: true })
], User);
export default User;
