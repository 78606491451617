import { SelectListVm } from "@/models/SelectListVm";
export class RangeVm {
    id;
    shortName;
    fullName;
    publicationName;
    brandName;
    canBeDeleted;
    constructor({ id, shortName, fullName, publicationName, brandName, canBeDeleted }) {
        this.id = id;
        this.shortName = shortName;
        this.fullName = fullName;
        this.publicationName = publicationName;
        this.brandName = brandName;
        this.canBeDeleted = canBeDeleted;
    }
}
export class RangeAddOrEditVm {
    id;
    shortName;
    fullName;
    featureFeatureValueList;
    publicationId;
    pmnId;
    languageMappingId;
    defaultPmn;
    _publication;
    get publication() {
        return this._publication;
    }
    set publication(v) {
        this._publication = v;
        this.publicationId = v?.id;
    }
    brandId;
    _brand;
    get brand() {
        return this._brand;
    }
    set brand(v) {
        this._brand = v;
        this.brandId = v?.id;
    }
    _pmn;
    get pmn() {
        return this._pmn;
    }
    set pmn(v) {
        this._pmn = v;
        this.pmnId = v?.id;
    }
    _languageMapping;
    get languageMapping() {
        return this._languageMapping;
    }
    set languageMapping(v) {
        this._languageMapping = v;
        this.languageMappingId = v?.id;
    }
    constructor(range) {
        this.id = range.id;
        this.shortName = range.shortName;
        this.fullName = range.fullName;
        this._brand = SelectListVm.mapFromBrandVm(range.brand);
        this.brandId = range.brand.id;
        this._publication = SelectListVm.mapFromPublicationVm(range.publication);
        this.publicationId = range.publication.id;
        this._pmn = range.pmn ? SelectListVm.mapFromPmnVm(range.pmn) : null;
        this.pmnId = range.pmn?.id;
        this._languageMapping = range.languageMapping ? SelectListVm.mapFromLanguageMappingVm(range.languageMapping) : null;
        this.languageMappingId = range.languageMapping?.id;
        this.featureFeatureValueList = range.featureFeatureValueList;
        this.defaultPmn = range.defaultPmn;
    }
}
