import { isNullOrUndefined } from "@/common/Utils";
export class PaginationMetaData {
    currentPage;
    pageSize;
    totalPages;
    totalCount;
    constructor(totalCount, currentPage, pageSize, totalPages) {
        this.totalCount = totalCount;
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.totalPages = totalPages;
    }
}
export class PaginationRequest {
    pageNumber = 1;
    pageSize = 10;
    paginationMetaData;
    constructor(pageNumber, pageSize) {
        if (!isNullOrUndefined(pageNumber)) {
            this.pageNumber = pageNumber;
        }
        if (!isNullOrUndefined(pageSize)) {
            this.pageSize = pageSize;
        }
    }
    setMetadata({ totalCount, currentPage, pageSize, totalPages }) {
        this.paginationMetaData = new PaginationMetaData(totalCount, currentPage, pageSize, totalPages);
    }
}
