import { SelectListVm } from "@/models/SelectListVm";
export class ModelVm {
    id;
    shortName;
    fullName;
    year;
    rangeName;
    businessAreaName;
    divisionName;
    productCompanyName;
    machineCategoryName;
    publicationName;
    brandName;
    featureFeatureValueList;
    featureFeatureValueListLength;
    canBeDeleted = true;
    constructor({ id, shortName, fullName, year, rangeName, businessAreaName, divisionName, productCompanyName, machineCategoryName, publicationName, brandName, featureFeatureValueList }) {
        this.id = id;
        this.shortName = shortName;
        this.fullName = fullName;
        this.year = year;
        this.rangeName = rangeName;
        this.businessAreaName = businessAreaName;
        this.divisionName = divisionName;
        this.productCompanyName = productCompanyName;
        this.machineCategoryName = machineCategoryName;
        this.publicationName = publicationName;
        this.brandName = brandName;
        this.featureFeatureValueList = featureFeatureValueList;
        this.featureFeatureValueListLength = this.featureFeatureValueList.length;
    }
}
export class ModelAddOrEditVm {
    id;
    shortName;
    fullName;
    year;
    overrideProductConnect;
    featureFeatureValueList;
    rangeId;
    _range;
    get range() {
        return this._range;
    }
    set range(v) {
        this._range = v;
        this.rangeId = v?.id;
    }
    constructor(model) {
        this.id = model.id;
        this.shortName = model.shortName;
        this.fullName = model.fullName;
        this.year = model.year;
        this.featureFeatureValueList = model.featureFeatureValueList;
        this._range = SelectListVm.mapFromRangeVm(model.range);
        this.rangeId = model.range.id;
    }
}
