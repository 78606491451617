import { ACTION_TYPES, GETTER_TYPES } from "./action-types";
import { MUTATION_TYPES } from "./mutation-types";
export const STORE_MODULE_NAMES = {
    BUSINESSAREA: "BusinessArea",
    DIVISION: "Division",
    MACHINE_CATEGORY: "MachineCategory",
    PRODUCT_COMPANY: "ProductCompany",
    BRAND: "Brand",
    PUBLICATION: "Publication",
    RANGE: "Range",
    MODEL: "Model",
    USER: "User",
    LEGAL_ENTITY: "LegalEntity",
    ENGINEERED_SOLUTION: "EngineeredSolution",
    PUBLICATION_REQUEST: "PublicationRequest",
    LANGUAGE_MAPPING: "LanguageMapping",
    PMN: "PrintedMatterNumber",
    GENERATE: "Generate",
    TOPICS: "Topics"
};
export const DispatchHelper = {
    getList(module) {
        return `${module}/${ACTION_TYPES.GET_SELECTLIST}`;
    },
    getPagedList(module) {
        return `${module}/${ACTION_TYPES.GET_PAGEDLIST}`;
    },
    getById(module) {
        return `${module}/${ACTION_TYPES.GET_BY_ID}`;
    },
    add(module) {
        return `${module}/${ACTION_TYPES.ADD}`;
    },
    edit(module) {
        return `${module}/${ACTION_TYPES.EDIT}`;
    },
    setSort(module) {
        return `${module}/${MUTATION_TYPES.SET_SORT}`;
    },
    setError(module) {
        return `${module}/${MUTATION_TYPES.SET_ERROR}`;
    },
    delete(module) {
        return `${module}/${ACTION_TYPES.DELETE_ITEM}`;
    },
    setFilterMap(module) {
        return `${module}/${MUTATION_TYPES.SET_FILTERMAP}`;
    },
    getHasAccessGetter(module) {
        return `${module}/${GETTER_TYPES.HAS_ACCESS}`;
    },
    getAllEntities(module) {
        return `${module}/${ACTION_TYPES.GET_ALL_ENTITIES}`;
    },
    getTopicsByModelId() {
        return `${STORE_MODULE_NAMES.TOPICS}/${ACTION_TYPES.GET_TOPICS_BY_MODEL_ID}`;
    },
    getTopicsByModelModyear() {
        return `${STORE_MODULE_NAMES.TOPICS}/${ACTION_TYPES.GET_TOPICS_BY_MODEL_MODYEAR}`;
    },
    getTopicsByPublicationGuid() {
        return `${STORE_MODULE_NAMES.TOPICS}/${ACTION_TYPES.GET_TOPICS_BY_PUBLICATION_GUID}`;
    },
};
