import { DispatchHelper, STORE_MODULE_NAMES, } from "@/store/types/store-module-names";
// import Vue from "vue";
// import VueRouter, { RouteConfig } from "vue-router";
import { createRouter, createWebHistory } from 'vue-router';
import { ROUTENAMES } from "./route-names";
import store from "@/store";
import { dismissGlobalError } from "@/store/HelperFunctions";
import { registerGuard } from "./routerGuard";
const routes = [
    {
        path: '/',
        name: ROUTENAMES.HOME,
        component: () => import("@/views/Home.vue")
    },
    {
        path: '/publication/:id',
        name: ROUTENAMES.EDIT_PUBLICATION,
        props: {
            title: "Edit publication"
        },
        component: () => import("@/views/Overview.vue")
    },
    {
        path: '/ba',
        name: ROUTENAMES.BUSINESS_AREA,
        props: {
            title: "Business area",
            storeModuleName: STORE_MODULE_NAMES.BUSINESSAREA
        },
        component: () => import("@/views/Overview.vue")
    },
    {
        path: "/ba/:id",
        name: `EDIT_${ROUTENAMES.BUSINESS_AREA}`,
        props: (route) => ({
            title: "Business area",
            id: route.params.id,
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditBusinessArea.vue")
    },
    {
        path: "/div",
        name: ROUTENAMES.DIVISION,
        props: {
            title: "Division",
            storeModuleName: STORE_MODULE_NAMES.DIVISION,
        },
        component: () => import("@/views/Overview.vue")
    },
    {
        path: "/div/:id",
        name: `EDIT_${ROUTENAMES.DIVISION}`,
        props: (route) => ({
            title: "Division",
            id: route.params.id,
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditDivision.vue")
    },
    {
        path: "/machine",
        name: ROUTENAMES.MACHINE_CATEGORY,
        props: {
            title: "Machine category",
            storeModuleName: STORE_MODULE_NAMES.MACHINE_CATEGORY,
        },
        component: () => import("@/views/Overview.vue")
    },
    {
        path: "/machine/:id",
        name: `EDIT_${ROUTENAMES.MACHINE_CATEGORY}`,
        props: (route) => ({
            title: "Machine category",
            id: route.params.id,
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditMachineCategory.vue")
    },
    {
        path: "/pc",
        name: ROUTENAMES.PRODUCT_COMPANIES,
        props: {
            title: "Product company",
            storeModuleName: STORE_MODULE_NAMES.PRODUCT_COMPANY,
        },
        component: () => import("@/views/Overview.vue")
    },
    {
        path: "/pc/:id",
        name: `EDIT_${ROUTENAMES.PRODUCT_COMPANIES}`,
        props: (route) => ({
            title: "Product company",
            id: route.params.id,
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditProductCompany.vue")
    },
    {
        path: "/pub",
        name: ROUTENAMES.PUBLICATIONS,
        props: {
            title: "Publication",
            storeModuleName: STORE_MODULE_NAMES.PUBLICATION,
        },
        component: () => import("@/views/Overview.vue")
    },
    {
        path: "/pub/:id",
        name: `EDIT_${ROUTENAMES.PUBLICATIONS}`,
        props: (route) => ({
            title: "Publication",
            id: route.params.id,
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditPublication.vue")
    },
    {
        path: "/range",
        name: ROUTENAMES.RANGE,
        props: {
            title: "Range",
            storeModuleName: STORE_MODULE_NAMES.RANGE,
        },
        component: () => import("@/views/Overview.vue")
    },
    {
        path: "/range/:id",
        name: `EDIT_${ROUTENAMES.RANGE}`,
        props: (route) => ({
            title: "Range",
            id: route.params.id,
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditRange.vue")
    },
    {
        path: "/brand",
        name: ROUTENAMES.BRAND,
        props: {
            title: "Brand",
            storeModuleName: STORE_MODULE_NAMES.BRAND,
        },
        component: () => import("@/views/Overview.vue")
    },
    {
        path: "/brand/:id",
        name: `EDIT_${ROUTENAMES.BRAND}`,
        props: (route) => ({
            title: "Brand",
            id: route.params.id,
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditBrand.vue")
    },
    {
        path: "/models",
        name: ROUTENAMES.MODELS,
        props: {
            title: "Model",
            storeModuleName: STORE_MODULE_NAMES.MODEL,
        },
        component: () => import("@/views/Overview.vue")
    },
    {
        path: "/models/:id",
        name: `EDIT_${ROUTENAMES.MODELS}`,
        props: (route) => ({
            title: "Model",
            id: route.params.id,
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditModel.vue")
    },
    {
        path: "/models/:id/clone",
        name: `CLONE_${ROUTENAMES.MODELS}`,
        props: (route) => ({
            title: "Model",
            id: route.params.id,
            clone: true,
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditModel.vue")
    },
    {
        path: "/engineered_solutions",
        name: ROUTENAMES.ENGINEERED_SOLUTIONS,
        props: {
            title: "Engineered solution",
            storeModuleName: STORE_MODULE_NAMES.ENGINEERED_SOLUTION
        },
        component: () => import("@/views/Overview.vue")
    },
    {
        path: "/engineered_solutions/:id",
        name: `EDIT_${ROUTENAMES.ENGINEERED_SOLUTIONS}`,
        props: (route) => ({
            title: "Engineered solution",
            id: route.params.id
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditEngineeredSolution.vue")
    },
    {
        path: "/engineered_solutions/:id/clone",
        name: `CLONE_${ROUTENAMES.ENGINEERED_SOLUTIONS}`,
        props: (route) => ({
            title: "Engineered solution",
            id: route.params.id,
            clone: true
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditEngineeredSolution.vue")
    },
    {
        path: "/legalentity/:id",
        name: `EDIT_${ROUTENAMES.LEGAL_ENTITY}`,
        props: (route) => ({
            title: "Legal Entity",
            id: route.params.id,
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditLegalEntity.vue")
    },
    {
        path: "/legalentity",
        name: ROUTENAMES.LEGAL_ENTITY,
        props: {
            title: "Legal Entity",
            storeModuleName: STORE_MODULE_NAMES.LEGAL_ENTITY,
        },
        component: () => import("@/views/Overview.vue")
    },
    {
        path: "/pmn",
        name: ROUTENAMES.PMN,
        props: {
            title: "Printed matter number",
            storeModuleName: STORE_MODULE_NAMES.PMN
        },
        component: () => import("@/views/OverviewNoExtras.vue")
    },
    {
        path: "/pmn/:id",
        name: `EDIT_${ROUTENAMES.PMN}`,
        props: (route) => ({
            title: "Printed matter number",
            id: route.params.id,
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditPmn.vue")
    },
    {
        path: "/languagemappings/:id",
        name: `EDIT_${ROUTENAMES.LANGUAGE_MAPPINGS}`,
        props: (route) => ({
            title: "Language mappings",
            id: route.params.id
        }),
        component: () => import("@/views/AddOrEditViews/AddOrEditLanguageMapping.vue")
    },
    {
        path: "/languagemappings",
        name: ROUTENAMES.LANGUAGE_MAPPINGS,
        props: {
            title: "Language mappings",
            storeModuleName: STORE_MODULE_NAMES.LANGUAGE_MAPPING
        },
        component: () => import("@/views/OverviewNoExtras.vue")
    },
    {
        path: "/pubreq",
        name: ROUTENAMES.PUBLICATION_REQUEST,
        component: () => import("@/views/PublicationRequest.vue")
    },
    {
        path: "/unauth",
        name: ROUTENAMES.UNAUTH,
        component: () => import("@/views/Unauthview.vue")
    }
];
const router = createRouter({
    history: createWebHistory("#"),
    linkExactActiveClass: "active",
    routes
});
router.beforeEach((to, from, next) => {
    if (store.getters[DispatchHelper.getHasAccessGetter(STORE_MODULE_NAMES.USER)](to.name ?? "")) {
        next();
    }
    else {
        next({
            name: ROUTENAMES.UNAUTH,
        });
    }
});
router.afterEach(() => {
    dismissGlobalError();
});
registerGuard(router);
export default router;
