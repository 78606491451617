export const ROUTENAMES = {
    HOME: "home",
    UNAUTH: "unauthorized",
    EDIT_PUBLICATION: "publication",
    BUSINESS_AREA: "business area",
    DIVISION: "division",
    MACHINE_CATEGORY: "machine category",
    PRODUCT_COMPANIES: "product companies",
    PUBLICATIONS: "publications",
    BRAND: "brand",
    RANGE: "range",
    MODELS: "models",
    ENGINEERED_SOLUTIONS: "engineered solutions",
    F_FV: "f/fv",
    LEGAL_ENTITY: "legal entity",
    PMN: "pmn",
    PUBLICATION_REQUEST: "publication request",
    LANGUAGE_MAPPINGS: "language mappings"
};
