import { createStore } from "vuex";
import BusinessArea from "@/store/modules/businessArea";
import Division from "@/store/modules/division";
import MachineCategory from "@/store/modules/machineCategory";
import ProductCompany from "@/store/modules/productCompany";
import Brand from "@/store/modules/brand";
import Publication from "@/store/modules/publication";
import Range from "@/store/modules/range";
import Model from "@/store/modules/model";
import EngineeredSolution from "@/store/modules/engineeredSolution";
import User from "@/store/modules/user";
import LegalEntity from "@/store/modules/legalEntity";
import Generate from "@/store/modules/generate";
import PublicationRequest from "@/store/modules/publicationRequest";
import PrintedMatterNumber from "@/store/modules/printedMatterNumber";
import Topics from "@/store/modules/topics";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
import { ACTION_TYPES } from "./types/action-types";
import { ddpService } from "@/services/DdpService";
import { STORE_MODULE_NAMES } from "./types/store-module-names";
import LanguageMapping from "./modules/languageMapping";
class State {
    isGettingData = false;
    globalError = null;
    unauthorized = false;
    isProccessing = false;
}
const mutations = {
    [MUTATION_TYPES.SET_GETTINGDATA](state, isGettingData) {
        state.isGettingData = isGettingData;
    },
    [MUTATION_TYPES.SET_GLOBAL_ERROR](state, data) {
        state.globalError = data == "" ? null : data;
    },
    [MUTATION_TYPES.SET_UNAUTHORIZED](state, unauthorized) {
        state.unauthorized = unauthorized;
    },
    [MUTATION_TYPES.SET_PROCCESSING](state, isProccessing) {
        state.isProccessing = isProccessing;
    },
};
const actions = {
    async [ACTION_TYPES.TRANSLATE_PRODUCT_NUMBERS](state, productNumbers) {
        const result = await ddpService.post("specials/translateproductnumbers", { productNumbers: productNumbers }, STORE_MODULE_NAMES.GENERATE);
        return result.result;
    },
    async [ACTION_TYPES.TRANSLATE_SERIAL_NUMBER](state, serialNumber) {
        const result = await ddpService.post("specials/translateserialnumber", { serialNumber: serialNumber }, STORE_MODULE_NAMES.GENERATE);
        return result.result;
    },
    async [ACTION_TYPES.GENERATE_BY_PRODUCT_NUMBERS](state, productNumbers) {
        const result = await ddpService.post("generate/productnumbers", productNumbers, STORE_MODULE_NAMES.GENERATE);
        return result.result;
    },
    async [ACTION_TYPES.GENERATE_BY_SERIAL_NUMBER](state, serialNumber) {
        const result = await ddpService.post("generate/serialnumber", serialNumber, STORE_MODULE_NAMES.GENERATE);
        return result.result;
    },
    async [ACTION_TYPES.GENERATE_BY_FEATURES](state, features) {
        const result = await ddpService.post("generate/features", features, STORE_MODULE_NAMES.GENERATE);
        return result.result;
    },
    async [ACTION_TYPES.VIEW_BY_PRODUCT_NUMBERS](state, request) {
        const result = await ddpService.post("viewer/productnumbers", request, STORE_MODULE_NAMES.GENERATE);
        return result.result;
    },
    async [ACTION_TYPES.VIEW_BY_SERIAL_NUMBER](state, request) {
        const result = await ddpService.post("viewer", request, STORE_MODULE_NAMES.GENERATE);
        return result.result;
    },
    async [ACTION_TYPES.VIEW_BY_FEATURES](state, request) {
        const result = await ddpService.post("viewer", request, STORE_MODULE_NAMES.GENERATE);
        return result.result;
    },
    async [ACTION_TYPES.GENERATE_RANGE_BOOK](state, payload) {
        const result = await ddpService.post(`range/generate`, payload, STORE_MODULE_NAMES.RANGE);
        return result.result;
    },
    async [ACTION_TYPES.GENERATE_ENGINEERED_SOLUTION](state, payload) {
        const result = await ddpService.post(`engineeredsolution/generate`, payload, STORE_MODULE_NAMES.ENGINEERED_SOLUTION);
        return result.result;
    },
    async [ACTION_TYPES.VIEW_ENGINEERED_SOLUTION](state, payload) {
        const result = await ddpService.post(`engineeredsolution/view`, payload, STORE_MODULE_NAMES.ENGINEERED_SOLUTION);
        return result.result;
    },
    async [ACTION_TYPES.DOWNLOAD_ENGINEERED_SOLUTION](state, payload) {
        const result = await ddpService.post(`engineeredsolution/download`, payload, STORE_MODULE_NAMES.ENGINEERED_SOLUTION);
        return result.result;
    },
};
const store = createStore({
    state: new State(),
    mutations: mutations,
    actions: actions,
    modules: {
        BusinessArea,
        Division,
        MachineCategory,
        ProductCompany,
        Brand,
        Publication,
        Range,
        Model,
        EngineeredSolution,
        User,
        LegalEntity,
        Generate,
        PublicationRequest,
        PrintedMatterNumber,
        LanguageMapping,
        Topics,
    },
});
export default store;
