var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { FailActionResult } from "@/common/helpers/ApiHelper";
import { ddpService } from "@/services/DdpService";
import { ACTION_TYPES } from "@/store/types/action-types";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
let Generate = class Generate extends VuexModule {
    error = null;
    languagesSelectList = [];
    static State;
    paginationActive = true;
    [_b = MUTATION_TYPES.SET_ERROR](error) {
        this.error = error;
    }
    [_c = MUTATION_TYPES.SET_SELECTLIST](data) {
        if (data) {
            this.languagesSelectList = [...data];
        }
    }
    async [_d = ACTION_TYPES.GET_SELECTLIST](refresh = false) {
        if (!refresh && this.languagesSelectList.length > 0) {
            return;
        }
        const result = await ddpService.getAllItems("generate/languages");
        if (result.isSuccess) {
            const languages = result.result;
            this.context.commit(MUTATION_TYPES.SET_SELECTLIST, languages);
        }
    }
};
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof FailActionResult !== "undefined" && FailActionResult) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], Generate.prototype, _b, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], Generate.prototype, _c, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], Generate.prototype, _d, null);
Generate = __decorate([
    Module({ namespaced: true })
], Generate);
export default Generate;
