import { isNullOrUndefined } from "@/common/Utils";
import { apiHelper, SuccessActionResult, } from "@/common/helpers/ApiHelper";
import axios from "axios";
import { handleApiCallError } from "@/store/HelperFunctions";
import store from "@/store";
import { STORE_MODULE_NAMES } from "@/store/types/store-module-names";
import getTokenFromSessionStorage, { acquireAuth, } from "@/composition-api/AuthTokenFetcher";
import { msalInstance } from "@/authConfig";
class DdpService {
    async getAuthorizationConfig() {
        await acquireAuth(msalInstance);
        // in some cases getTokenFromSessionStorage isn't updated fast enough,
        // but in that case the store should reflect the change...
        const token = getTokenFromSessionStorage() ?? store.state.User.accessToken;
        if (token && token != "") {
            return {
                headers: { Authorization: `Bearer ${token}` },
            };
        }
        return {};
    }
    async getAllItems(controllerName, pagination, sort, filterMap) {
        const url = apiHelper.createUrl(controllerName);
        let request = "?";
        if (!isNullOrUndefined(pagination?.pageNumber)) {
            request += `PageNumber=${pagination?.pageNumber}&PageSize=${pagination?.pageSize}&`;
        }
        if (!isNullOrUndefined(sort) && sort !== "") {
            request += `sort=${sort}&`;
        }
        if (typeof filterMap != "undefined" && filterMap.size > 0) {
            const filterString = Array.from(filterMap).reduce((acc, curr) => `${acc};${curr[0]}:${curr[1]}`, "");
            request += `filter=${encodeURIComponent(filterString)}`;
        }
        try {
            const response = await axios.get(`${url}${request}`, await this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            return handleApiCallError(error);
        }
    }
    async getItemById(controllerName, id) {
        const url = apiHelper.createUrl(controllerName);
        try {
            const response = await axios.get(`${url}/${id}`, await this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            return handleApiCallError(error);
        }
    }
    async getItemByIdString(controllerName, id, storeModuleName) {
        const url = apiHelper.createUrl(controllerName);
        try {
            const response = await axios.get(`${url}/${id}`, await this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            return handleApiCallError(error, storeModuleName);
        }
    }
    async get(controllerName, id) {
        const url = apiHelper.createUrl(controllerName);
        try {
            const response = await axios.get(`${url}?id=${id}`, await this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            return handleApiCallError(error);
        }
    }
    async post(controllerName, data, storeModuleName) {
        const url = apiHelper.createUrl(controllerName);
        try {
            const response = await axios.post(url, data, await this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            return handleApiCallError(error, storeModuleName);
        }
    }
    async addItem(controllerName, data, storeModuleName) {
        const url = apiHelper.createUrl(controllerName);
        try {
            const response = await axios.put(`${url}/add`, data, await this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            return handleApiCallError(error, storeModuleName);
        }
    }
    async editItem(controllerName, data, storeModuleName) {
        const url = apiHelper.createUrl(controllerName);
        try {
            const response = await axios.post(`${url}/edit`, data, await this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            return handleApiCallError(error, storeModuleName);
        }
    }
    async deleteItem(pubId, controllerName) {
        const url = apiHelper.createUrl(controllerName);
        try {
            const response = await axios.delete(`${url}/${pubId}`, await this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            return handleApiCallError(error);
        }
    }
    async getTopicsByModelId(modelId) {
        const url = apiHelper.createUrl(STORE_MODULE_NAMES.TOPICS);
        try {
            const response = await axios.get(`${url}/${modelId}`, await this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            return handleApiCallError(error);
        }
    }
    async getTopicsByModelModyear(query) {
        const url = apiHelper.createUrl(STORE_MODULE_NAMES.TOPICS);
        try {
            const response = await axios.get(`${url}?${query}`, await this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            return handleApiCallError(error);
        }
    }
    async getTopicsByPublicationGuid(query) {
        const url = apiHelper.createUrl(STORE_MODULE_NAMES.TOPICS);
        try {
            const response = await axios.post(`${url}`, query, await this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            return handleApiCallError(error);
        }
    }
}
export const ddpService = new DdpService();
