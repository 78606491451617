export function capitalizeInput(event) {
    if (typeof event === "object") {
        const tgt = event.target;
        const lastPos = tgt.selectionStart;
        tgt.value = tgt.value.toUpperCase();
        tgt.selectionStart = lastPos;
        tgt.selectionEnd = lastPos;
        return tgt.value;
    }
    return event.toString().toUpperCase();
}
