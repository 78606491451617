var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
import { __decorate, __metadata } from "tslib";
import { FailActionResult } from "@/common/helpers/ApiHelper";
import { CONTROLLERNAMES } from "@/common/types/ControllerNames";
import { isNullOrUndefined } from "@/common/Utils";
import { ModelVm, ModelAddOrEditVm, ModelDetailVm } from "@/models/model";
import { PaginationRequest } from "@/models/Pagination";
import { SelectListVm } from "@/models/SelectListVm";
import { ddpService } from "@/services/DdpService";
import { ACTION_TYPES } from "@/store/types/action-types";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
import { STORE_MODULE_NAMES } from "@/store/types/store-module-names";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
let Model = class Model extends VuexModule {
    models = [];
    model = null;
    overviewPagination = new PaginationRequest();
    pagination = new PaginationRequest();
    sort = "";
    overviewSort = "";
    overviewFilterMap = new Map();
    filterMap = new Map();
    error = null;
    paginationActive = true;
    modelSelectList = [];
    get getModel() {
        if (!isNullOrUndefined(this.model)) {
            return this.model;
        }
    }
    [_b = MUTATION_TYPES.SET_ERROR](error) {
        this.error = error;
    }
    [_c = MUTATION_TYPES.SET_SORT]({ value, isOverview }) {
        if (isOverview) {
            this.overviewSort = value;
        }
        else {
            this.sort = value;
        }
    }
    [_d = MUTATION_TYPES.SET_FILTERMAP]({ filterMap, isOverview }) {
        if (isOverview) {
            this.overviewFilterMap = filterMap;
        }
        else {
            this.filterMap = filterMap;
        }
    }
    [_e = MUTATION_TYPES.SET_PAGINATION]({ pagination, isOverview = false }) {
        if (isOverview) {
            this.overviewPagination = pagination;
        }
        else {
            this.pagination = pagination;
        }
    }
    [_f = MUTATION_TYPES.SET_PAGEDLIST](data) {
        if (!isNullOrUndefined(data)) {
            this.models = [...data];
        }
    }
    async [_g = ACTION_TYPES.GET_PAGEDLIST]({ pagination, isOverview = false }) {
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, true, {
            root: true
        });
        const result = await ddpService.getAllItems(CONTROLLERNAMES.MODEL, pagination, isOverview ? this.overviewSort : this.sort, isOverview ? this.overviewFilterMap : this.filterMap);
        if (result.isSuccess) {
            const models = result.result[Object.getOwnPropertyNames(result.result)[0]].map((x) => {
                return new ModelVm(x);
            });
            pagination.setMetadata(result.result);
            this.context.commit(MUTATION_TYPES.SET_PAGINATION, {
                pagination,
                isOverview
            });
            this.context.commit(MUTATION_TYPES.SET_PAGEDLIST, models);
        }
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, false, {
            root: true
        });
    }
    async [_h = ACTION_TYPES.GET_SELECTLIST](refresh = false) {
        const result = await ddpService.getAllItems(CONTROLLERNAMES.MODEL);
        if (result.isSuccess) {
            const ranges = this.modelSelectList.length > 0 && !refresh
                ? this.modelSelectList
                : result.result[Object.getOwnPropertyNames(result.result)[0]].map((x) => {
                    return SelectListVm.mapFromModelVm(new ModelVm(x));
                });
            this.context.commit(MUTATION_TYPES.SET_SELECTLIST, ranges);
            this.modelSelectList.sort((a, b) => a.name.localeCompare(b.name));
        }
    }
    [_j = MUTATION_TYPES.SET_SELECTLIST](data) {
        if (!isNullOrUndefined(data)) {
            this.modelSelectList = [...data];
        }
    }
    [_l = MUTATION_TYPES.SET_BY_ID](data) {
        this.model = new ModelAddOrEditVm(data);
    }
    async [_m = ACTION_TYPES.GET_BY_ID](id) {
        if (id === 0)
            return;
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, true, {
            root: true
        });
        const result = await ddpService.getItemById(CONTROLLERNAMES.MODEL, id);
        if (result.isSuccess) {
            this.context.commit(MUTATION_TYPES.SET_BY_ID, result.result);
        }
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, false, {
            root: true
        });
    }
    async [_q = ACTION_TYPES.ADD](data) {
        //Call api
        const result = await ddpService.addItem(CONTROLLERNAMES.MODEL, data, STORE_MODULE_NAMES.MODEL);
        return result;
    }
    async [_t = ACTION_TYPES.EDIT](data) {
        //Call api
        const result = await ddpService.editItem(CONTROLLERNAMES.MODEL, data, STORE_MODULE_NAMES.MODEL);
        return result;
    }
    async [_v = ACTION_TYPES.DELETE_ITEM](id) {
        const result = await ddpService.deleteItem(id, CONTROLLERNAMES.MODEL);
        return result.isSuccess;
    }
};
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof FailActionResult !== "undefined" && FailActionResult) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], Model.prototype, _b, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Model.prototype, _c, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Model.prototype, _d, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Model.prototype, _e, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], Model.prototype, _f, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], Model.prototype, _g, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], Model.prototype, _h, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], Model.prototype, _j, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_k = typeof ModelDetailVm !== "undefined" && ModelDetailVm) === "function" ? _k : Object]),
    __metadata("design:returntype", void 0)
], Model.prototype, _l, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", Promise)
], Model.prototype, _m, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_o = typeof ModelAddOrEditVm !== "undefined" && ModelAddOrEditVm) === "function" ? _o : Object]),
    __metadata("design:returntype", typeof (_p = typeof Promise !== "undefined" && Promise) === "function" ? _p : Object)
], Model.prototype, _q, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_r = typeof ModelAddOrEditVm !== "undefined" && ModelAddOrEditVm) === "function" ? _r : Object]),
    __metadata("design:returntype", typeof (_s = typeof Promise !== "undefined" && Promise) === "function" ? _s : Object)
], Model.prototype, _t, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", typeof (_u = typeof Promise !== "undefined" && Promise) === "function" ? _u : Object)
], Model.prototype, _v, null);
Model = __decorate([
    Module({ namespaced: true })
], Model);
export default Model;
