export class LanguageMappingVm {
    id;
    code;
    display;
    defaultNumber;
    languageMappingValues;
    canBeDeleted = true;
    constructor({ id, code, display, defaultNumber, languageMappingValues: languageMappingValues, canBeDeleted, }) {
        this.id = id;
        this.code = code;
        this.display = display;
        this.defaultNumber = defaultNumber;
        this.languageMappingValues = languageMappingValues;
        this.canBeDeleted = canBeDeleted;
    }
}
export class LanguageMappingValue {
    id;
    languageMappingId;
    code;
    value;
    constructor({ id, languageMappingId, code, value, }) {
        this.id = id;
        this.languageMappingId = languageMappingId;
        this.code = code;
        this.value = value;
    }
}
