export function isNullOrUndefined(input) {
    return input == null || typeof input == "undefined";
}
export function nullWhenEmptyOrUndefined(input) {
    if (isNullOrUndefined(input))
        return null;
    if (input.length === 0)
        return null;
    return input;
}
export function currencyRound(input) {
    if (isNullOrUndefined(input))
        return input;
    return Math.round((input + Number.EPSILON) * 100) / 100;
}
