export const CONTROLLERNAMES = {
    PUBLICATIONS: "publication",
    BUSINESS_AREA: "businessarea",
    DIVISION: "division",
    MACHINE_CATEGORY: "machinecategory",
    PRODUCT_COMPANY: "productcompany",
    BRAND: "brand",
    RANGE: "range",
    MODEL: "model",
    USER: "user",
    LEGAL_ENTITY: "legalentity",
    PMN: "pmn",
    LANGUAGE_MAPPING: "languagemapping",
    ENGINEERED_SOLUTION: "engineeredsolution",
    TOPICS: "topics",
};
