var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { GETTER_TYPES } from "@/store/types/action-types";
import { isNullOrUndefined } from "@/common/Utils";
import { ROLE } from "@/models/SecurityRoles";
import { UserVm, RoleVm } from "@/models/user";
import { ROUTENAMES } from "@/router/route-names";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
let User = class User extends VuexModule {
    user = null;
    accessToken = null;
    name = "";
    rules = {
        [ROUTENAMES.HOME]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR, ROLE.ENGINEERED_SOLUTION_EDITOR],
        [ROUTENAMES.BUSINESS_AREA]: [ROLE.ADMIN],
        [`EDIT_${ROUTENAMES.BUSINESS_AREA}`]: [ROLE.ADMIN],
        [ROUTENAMES.DIVISION]: [ROLE.ADMIN],
        [`EDIT_${ROUTENAMES.DIVISION}`]: [ROLE.ADMIN],
        [ROUTENAMES.MACHINE_CATEGORY]: [ROLE.ADMIN],
        [`EDIT_${ROUTENAMES.MACHINE_CATEGORY}`]: [ROLE.ADMIN],
        [ROUTENAMES.PRODUCT_COMPANIES]: [ROLE.ADMIN],
        [`EDIT_${ROUTENAMES.PRODUCT_COMPANIES}`]: [ROLE.ADMIN],
        [ROUTENAMES.PUBLICATIONS]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR, ROLE.ENGINEERED_SOLUTION_EDITOR],
        [`EDIT_${ROUTENAMES.PUBLICATIONS}`]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR],
        [ROUTENAMES.EDIT_PUBLICATION]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR],
        [ROUTENAMES.BRAND]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR, ROLE.ENGINEERED_SOLUTION_EDITOR],
        [`EDIT_${ROUTENAMES.BRAND}`]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR],
        [ROUTENAMES.RANGE]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR, ROLE.ENGINEERED_SOLUTION_EDITOR],
        [`EDIT_${ROUTENAMES.RANGE}`]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR],
        [ROUTENAMES.MODELS]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR, ROLE.ENGINEERED_SOLUTION_EDITOR],
        [`EDIT_${ROUTENAMES.MODELS}`]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR],
        [`CLONE_${ROUTENAMES.MODELS}`]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR],
        [ROUTENAMES.ENGINEERED_SOLUTIONS]: [ROLE.ADMIN, ROLE.ENGINEERED_SOLUTION_EDITOR],
        [`EDIT_${ROUTENAMES.ENGINEERED_SOLUTIONS}`]: [ROLE.ADMIN, ROLE.ENGINEERED_SOLUTION_EDITOR],
        [`CLONE_${ROUTENAMES.ENGINEERED_SOLUTIONS}`]: [ROLE.ADMIN, ROLE.ENGINEERED_SOLUTION_EDITOR],
        [ROUTENAMES.LEGAL_ENTITY]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR, ROLE.ENGINEERED_SOLUTION_EDITOR],
        [`EDIT_${ROUTENAMES.LEGAL_ENTITY}`]: [ROLE.ADMIN],
        [ROUTENAMES.LANGUAGE_MAPPINGS]: [ROLE.ADMIN],
        [`EDIT_${ROUTENAMES.LANGUAGE_MAPPINGS}`]: [ROLE.ADMIN],
        [ROUTENAMES.PUBLICATION_REQUEST]: [ROLE.ADMIN, ROLE.MAPPING_TOOL_EDITOR],
        [ROUTENAMES.PMN]: [ROLE.ADMIN],
        [`EDIT_${ROUTENAMES.PMN}`]: [ROLE.ADMIN],
    };
    get [GETTER_TYPES.HAS_ACCESS]() {
        return (routename) => {
            const neededRoles = this.rules[routename];
            // return (isNullOrUndefined(neededRoles) || neededRoles.includes(this.role));
            // return (!isNullOrUndefined(neededRoles) && neededRoles.includes(this.role));
            if (routename === ROUTENAMES.UNAUTH)
                return true;
            return (!isNullOrUndefined(neededRoles) &&
                neededRoles.some(x => this.user?.roles ?
                    this.user?.roles.map((x) => x.key).includes(x) :
                    false));
        };
    }
    get isAdmin() {
        return !!this.user?.roles.find(x => x.key === ROLE.ADMIN);
    }
    [_a = MUTATION_TYPES.SET_USER](data) {
        this.user = null;
        if (data) {
            const roles = data.idTokenClaims?.roles?.map((x) => new RoleVm(x));
            const user = new UserVm({ id: data.localAccountId, roles });
            this.user = Object.assign({}, user);
        }
    }
    [_b = MUTATION_TYPES.SET_ACCESS_TOKEN](data) {
        this.accessToken = data;
    }
    [_c = MUTATION_TYPES.SET_USER_NAME](data) {
        this.name = data;
    }
};
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], User.prototype, _a, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], User.prototype, _b, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], User.prototype, _c, null);
User = __decorate([
    Module({ namespaced: true })
], User);
export default User;
