import { SelectListVm } from "@/models/SelectListVm";
export class PublicationVm {
    id;
    shortName;
    fullName;
    publicationGuid;
    divisionName;
    productCompanyName;
    machineCategoryName;
    canBeDeleted;
    constructor({ id, shortName, fullName, publicationGuid, divisionName, productCompanyName, machineCategoryName, canBeDeleted }) {
        this.id = id;
        this.shortName = shortName;
        this.fullName = fullName;
        this.publicationGuid = publicationGuid;
        this.divisionName = divisionName;
        this.productCompanyName = productCompanyName;
        this.machineCategoryName = machineCategoryName;
        this.canBeDeleted = canBeDeleted;
    }
}
export class PublicationAddOrEditVm {
    id;
    shortName;
    fullName;
    publicationGuid;
    divisionId;
    _division;
    get division() {
        return this._division;
    }
    set division(newValue) {
        this._division = newValue;
        this.divisionId = newValue?.id;
    }
    productCompanyId;
    _productCompany;
    get productCompany() {
        return this._productCompany;
    }
    set productCompany(v) {
        this._productCompany = v;
        this.productCompanyId = v?.id;
    }
    machineCategoryId;
    _machineCategory;
    get machineCategory() {
        return this._machineCategory;
    }
    set machineCategory(v) {
        this._machineCategory = v;
        this.machineCategoryId = v?.id;
    }
    constructor(publication) {
        this.id = publication.id;
        this.shortName = publication.shortName;
        this.fullName = publication.fullName;
        this.publicationGuid = publication.publicationGuid;
        this._division = SelectListVm.mapFromDivisionVm(publication.division);
        this.divisionId = publication.division.id;
        this._productCompany = SelectListVm.mapFromProductCompanyVm(publication.productCompany);
        this.productCompanyId = publication.productCompany.id;
        this._machineCategory = SelectListVm.mapFromMachineCategoryVm(publication.machineCategory);
        this.machineCategoryId = publication.machineCategory.id;
    }
}
