export class BusinessAreaVm {
    id;
    shortName;
    fullName;
    canBeDeleted;
    constructor({ id, shortName, fullName, canBeDeleted }) {
        this.id = id;
        this.shortName = shortName;
        this.fullName = fullName;
        this.canBeDeleted = canBeDeleted;
    }
}
