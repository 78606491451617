import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "invalid-feedback"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_b_form_input = _resolveComponent("b-form-input");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_b_form_input, {
            id: _ctx.propertyName,
            modelValue: _ctx.isEdit ? _ctx.model : _ctx.inputValue,
            type: "text",
            class: _normalizeClass({
                'form-control': true,
                'is-invalid': _ctx.inValidState,
                required: _ctx.required,
            }),
            placeholder: _ctx.placeholder,
            readonly: _ctx.readonly,
            disabled: _ctx.disabled,
            state: _ctx.state,
            onKeypress: _ctx.resetErrorMessage,
            onBlur: _ctx.validateInput,
            onInput: _cache[0] || (_cache[0] = ($event) => (_ctx.inputValue = _ctx.capitalizeInput ? _ctx.capitalize($event) : $event))
        }, null, 8, ["id", "modelValue", "class", "placeholder", "readonly", "disabled", "state", "onKeypress", "onBlur"]),
        (_ctx.inValidState)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.message), 1))
            : _createCommentVNode("", true)
    ]));
}
