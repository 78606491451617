import { SelectListVm } from "@/models/SelectListVm";
export class DivisionVm {
    id;
    shortName;
    fullName;
    businessAreaName;
    canBeDeleted;
    constructor({ id, shortName, fullName, businessAreaName, canBeDeleted }) {
        this.id = id;
        this.shortName = shortName;
        this.fullName = fullName;
        this.businessAreaName = businessAreaName;
        this.canBeDeleted = canBeDeleted;
    }
}
export class DivisionAddOrEditVm {
    id;
    shortName;
    fullName;
    businessAreaId;
    _businessArea;
    get businessArea() {
        return this._businessArea;
    }
    set businessArea(newValue) {
        this._businessArea = newValue;
        this.businessAreaId = newValue?.id;
    }
    constructor(division) {
        this.id = division.id;
        this.shortName = division.shortName;
        this.fullName = division.fullName;
        this._businessArea = new SelectListVm(division.businessArea.id, division.businessArea.fullName);
        this.businessAreaId = division.businessArea.id;
    }
}
