export const MUTATION_TYPES = {
    DELETE_ITEM: "setDeleteItem",
    SET_GETTINGDATA: "setGettingData",
    SET_SELECTLIST: "setSelectList",
    SET_PAGEDLIST: "setPagedList",
    SET_BY_ID: "setById",
    SET_PAGINATION: "setPaginationRequest",
    SET_SORT: "setSort",
    SET_FILTERMAP: "setFilterMap",
    SET_ERROR: "setError",
    SET_GLOBAL_ERROR: "setGlobalError",
    SET_UNAUTHORIZED: "setUnauthorized",
    SET_USER: "setUser",
    SET_USER_NAME: "setUserName",
    SET_ACCESS_TOKEN: "setAccessToken",
    SET_PROCCESSING: "setProccessing",
    SET_PUBLICATION_REQUEST: "setPublicationRequest",
    SET_ALL_ENTITIES: "setAllEntities",
};
