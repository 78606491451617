import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiScope, apiTokenRequest, loginRequest } from "../authConfig";
import { dismissGlobalError, handleApiCallError } from "@/store/HelperFunctions";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
import { STORE_MODULE_NAMES } from "@/store/types/store-module-names";
import store from "@/store";
export default function getTokenFromSessionStorage() {
    const appKeys = sessionStorage.getItem(`msal.token.keys.${process.env.VUE_APP_APPSECURITY_CLIENTID ? process.env.VUE_APP_APPSECURITY_CLIENTID : ""}`);
    const activeAccount = sessionStorage.getItem(`msal.${process.env.VUE_APP_APPSECURITY_CLIENTID ? process.env.VUE_APP_APPSECURITY_CLIENTID : ""}.active-account`);
    if (appKeys && activeAccount) {
        const msalTokenKeys = JSON.parse(appKeys);
        const accessTokenKey = msalTokenKeys.accessToken.find(x => x.includes(activeAccount) && x.toLowerCase().includes(apiScope.toLowerCase()));
        if (accessTokenKey === undefined)
            return null;
        const tokenObject = JSON.parse(sessionStorage.getItem(`${accessTokenKey}`) ?? "");
        if (tokenObject && tokenObject?.secret) {
            return tokenObject.secret;
        }
    }
    return null;
}
function updateStoreForAcquiredToken(accessToken) {
    store.commit(`${STORE_MODULE_NAMES.USER}/${MUTATION_TYPES.SET_ACCESS_TOKEN}`, accessToken);
    store.commit(MUTATION_TYPES.SET_UNAUTHORIZED, false);
    dismissGlobalError();
}
export async function acquireAuth(instance) {
    const accessTokenRequest = {
        ...apiTokenRequest,
        account: instance.getActiveAccount(),
    };
    if (accessTokenRequest.account) {
        await instance
            .acquireTokenSilent(accessTokenRequest)
            .then((authResult) => {
            updateStoreForAcquiredToken(authResult.accessToken);
        })
            .catch(async (error) => {
            // Acquire token interactive failure
            if (error instanceof InteractionRequiredAuthError) {
                await instance
                    .acquireTokenPopup(loginRequest)
                    .catch(function (error) {
                    handleApiCallError(error);
                });
                return;
            }
            handleApiCallError(error);
        });
    }
}
