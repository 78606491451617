var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
import { __decorate, __metadata } from "tslib";
import { FailActionResult } from "@/common/helpers/ApiHelper";
import { CONTROLLERNAMES } from "@/common/types/ControllerNames";
import { isNullOrUndefined } from "@/common/Utils";
import { LanguageMappingVm } from "@/models/languageMapping";
import { PaginationRequest } from "@/models/Pagination";
import { SelectListVm } from "@/models/SelectListVm";
import { ddpService } from "@/services/DdpService";
import { ACTION_TYPES } from "@/store/types/action-types";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
import { STORE_MODULE_NAMES } from "@/store/types/store-module-names";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
let LanguageMapping = class LanguageMapping extends VuexModule {
    languageMappings = [];
    languageMapping = null;
    languageMappingSelectList = [];
    error = null;
    get getLanguageMapping() {
        if (!isNullOrUndefined(this.languageMapping)) {
            return this.languageMapping;
        }
    }
    [_b = MUTATION_TYPES.SET_ERROR](error) {
        this.error = error;
    }
    [_c = MUTATION_TYPES.SET_SELECTLIST](data) {
        if (!isNullOrUndefined(data)) {
            this.languageMappingSelectList = [...data];
        }
    }
    [_d = MUTATION_TYPES.SET_ALL_ENTITIES](data) {
        if (!isNullOrUndefined(data)) {
            this.languageMappings = [...data];
        }
    }
    async [_e = ACTION_TYPES.GET_ALL_ENTITIES]() {
        const result = await ddpService.getAllItems(CONTROLLERNAMES.LANGUAGE_MAPPING);
        if (result.isSuccess) {
            const data = result.result[Object.getOwnPropertyNames(result.result)[0]].map((x) => {
                return new LanguageMappingVm(x);
            });
            this.context.commit(MUTATION_TYPES.SET_ALL_ENTITIES, data);
        }
    }
    async [_f = ACTION_TYPES.GET_SELECTLIST](refresh = false) {
        const result = await ddpService.getAllItems(CONTROLLERNAMES.LANGUAGE_MAPPING);
        if (result.isSuccess) {
            const mappings = this.languageMappingSelectList.length > 0 && !refresh
                ? this.languageMappingSelectList
                : result.result[Object.getOwnPropertyNames(result.result)[0]].map((x) => {
                    return SelectListVm.mapFromLanguageMappingVm(new LanguageMappingVm(x));
                });
            this.context.commit(MUTATION_TYPES.SET_SELECTLIST, mappings);
            this.languageMappings.sort((a, b) => a.display.localeCompare(b.display));
        }
    }
    async [_h = ACTION_TYPES.GET_PAGEDLIST](pagination) {
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, true, {
            root: true
        });
        const result = await ddpService.getAllItems(CONTROLLERNAMES.LANGUAGE_MAPPING, pagination);
        if (result.isSuccess) {
            const data = result.result[Object.getOwnPropertyNames(result.result)[0]].map((x) => {
                return new LanguageMappingVm(x);
            });
            pagination.setMetadata(result.result);
            this.context.commit(MUTATION_TYPES.SET_PAGEDLIST, data);
        }
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, false, {
            root: true
        });
    }
    [_k = MUTATION_TYPES.SET_BY_ID](data) {
        this.languageMapping = new LanguageMappingVm(data);
    }
    async [_l = ACTION_TYPES.GET_BY_ID](id) {
        if (id === 0)
            return;
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, true, {
            root: true
        });
        const result = await ddpService.getItemById(CONTROLLERNAMES.LANGUAGE_MAPPING, id);
        if (result.isSuccess) {
            this.context.commit(MUTATION_TYPES.SET_BY_ID, result.result);
        }
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, false, {
            root: true
        });
    }
    async [_p = ACTION_TYPES.ADD](data) {
        //Call api
        const result = await ddpService.addItem(CONTROLLERNAMES.LANGUAGE_MAPPING, data, STORE_MODULE_NAMES.LANGUAGE_MAPPING);
        if (result.isSuccess) {
            this.context.dispatch(ACTION_TYPES.GET_SELECTLIST, true);
        }
        return result;
    }
    async [_s = ACTION_TYPES.EDIT](data) {
        //Call api
        const result = await ddpService.editItem(CONTROLLERNAMES.LANGUAGE_MAPPING, data, STORE_MODULE_NAMES.LANGUAGE_MAPPING);
        if (result.isSuccess) {
            this.context.dispatch(ACTION_TYPES.GET_SELECTLIST, true);
        }
        return result;
    }
    async [_u = ACTION_TYPES.DELETE_ITEM](id) {
        const result = await ddpService.deleteItem(id, CONTROLLERNAMES.LANGUAGE_MAPPING);
        return result.isSuccess;
    }
};
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof FailActionResult !== "undefined" && FailActionResult) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], LanguageMapping.prototype, _b, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], LanguageMapping.prototype, _c, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], LanguageMapping.prototype, _d, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], LanguageMapping.prototype, _e, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], LanguageMapping.prototype, _f, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_g = typeof PaginationRequest !== "undefined" && PaginationRequest) === "function" ? _g : Object]),
    __metadata("design:returntype", Promise)
], LanguageMapping.prototype, _h, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_j = typeof LanguageMappingVm !== "undefined" && LanguageMappingVm) === "function" ? _j : Object]),
    __metadata("design:returntype", void 0)
], LanguageMapping.prototype, _k, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", Promise)
], LanguageMapping.prototype, _l, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_m = typeof LanguageMappingVm !== "undefined" && LanguageMappingVm) === "function" ? _m : Object]),
    __metadata("design:returntype", typeof (_o = typeof Promise !== "undefined" && Promise) === "function" ? _o : Object)
], LanguageMapping.prototype, _p, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_q = typeof LanguageMappingVm !== "undefined" && LanguageMappingVm) === "function" ? _q : Object]),
    __metadata("design:returntype", typeof (_r = typeof Promise !== "undefined" && Promise) === "function" ? _r : Object)
], LanguageMapping.prototype, _s, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", typeof (_t = typeof Promise !== "undefined" && Promise) === "function" ? _t : Object)
], LanguageMapping.prototype, _u, null);
LanguageMapping = __decorate([
    Module({ namespaced: true })
], LanguageMapping);
export default LanguageMapping;
