import { FailActionResult } from "@/common/helpers/ApiHelper";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
import store from "@/store";
import { StatusCodes } from "@/common/types/StatusCodes";
import { DispatchHelper } from "./types/store-module-names";
export const dismissGlobalError = () => {
    store.commit(MUTATION_TYPES.SET_GLOBAL_ERROR, null);
};
export const setGlobalError = () => {
    store.commit(MUTATION_TYPES.SET_GLOBAL_ERROR, "Something went wrong");
};
export const setUnauthorized = () => {
    store.commit(MUTATION_TYPES.SET_GLOBAL_ERROR, "Session expired");
    store.commit(MUTATION_TYPES.SET_UNAUTHORIZED, true);
};
export const setErrorOnModule = (storeModule, error) => {
    store.commit(DispatchHelper.setError(storeModule), error);
};
export const handleApiCallError = (error, storeModuleName) => {
    if (error?.response) {
        const failActionError = new FailActionResult(error?.response);
        const errorCodes = [StatusCodes.BadRequest, StatusCodes.NotFound];
        if (errorCodes.includes(failActionError.status ?? 0) && storeModuleName) {
            setErrorOnModule(storeModuleName, failActionError);
        }
        else if (failActionError.status == StatusCodes.Unauthorized) {
            setUnauthorized();
        }
        else if (failActionError.status === 500) {
            const serverError = {
                isSuccess: false,
                status: 500,
            };
            setErrorOnModule(storeModuleName ?? "", serverError);
        }
        return failActionError;
    }
    setGlobalError();
    return FailActionResult.GetDefaultFailActionResult();
};
const filterErrorsByPropertyName = (error, propertyName) => {
    if (error == null)
        return null;
    return error.errorModel?.errors.filter((x) => x.propertyName.toLowerCase() == propertyName.toLowerCase());
};
export const getValidationMessage = (error, propertyName) => {
    if (error.errorModel && error.errorModel.errors.length > 0)
        return filterErrorsByPropertyName(error, propertyName)
            ?.map((x) => x.message)
            .join(" & ");
    return null;
};
export const isInValidModelState = (error, propertyName) => {
    if (error == null)
        return false;
    const filteredErrors = filterErrorsByPropertyName(error, propertyName);
    return (error?.status == StatusCodes.BadRequest &&
        filteredErrors != null &&
        filteredErrors.length > 0);
};
export const resetErrorMessage = (storeModule) => {
    store.commit(DispatchHelper.setError(storeModule));
};
export const onSave = async (model, storeModule, goBack, onSuccess, onFailed) => {
    if (model != null) {
        let response;
        if (model.id && model.id != 0) {
            response = await store.dispatch(DispatchHelper.edit(storeModule), model);
        }
        else {
            response = await store.dispatch(DispatchHelper.add(storeModule), model);
        }
        if (response.isSuccess) {
            onSuccess(storeModule);
            goBack();
        }
        else if (response.errorModel) {
            response.errorModel.errors?.forEach((x) => onFailed(storeModule, x.message));
        }
        else {
            onFailed(storeModule, `status code ${response.status} received.`);
            setGlobalError();
        }
    }
};
