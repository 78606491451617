export const ACTION_TYPES = {
    DELETE_ITEM: "deleteItem",
    GET_SELECTLIST: "getSelectList",
    GET_PAGEDLIST: "getPagedList",
    GET_BY_ID: "getById",
    ADD: "add",
    EDIT: "edit",
    TRANSLATE_PRODUCT_NUMBERS: "translateProductNumbers",
    TRANSLATE_SERIAL_NUMBER: "translateSerialNumber",
    GENERATE_BY_PRODUCT_NUMBERS: "generateByProductNumbers",
    GENERATE_BY_SERIAL_NUMBER: "generateBySerialNumber",
    GENERATE_BY_FEATURES: "generateByFeatures",
    VIEW_BY_PRODUCT_NUMBERS: "viewByProductNumbers",
    VIEW_BY_SERIAL_NUMBER: "viewBySerialNumber",
    VIEW_BY_FEATURES: "viewByFeatures",
    SEARCH_PUBLICATION_REQUEST: "searchPublicationRequest",
    GENERATE_RANGE_BOOK: "generateRangeBook",
    GET_ALL_ENTITIES: "getAllEntities",
    GET_TOPICS_BY_MODEL_ID: "getTopicsByModelId",
    GET_TOPICS_BY_MODEL_MODYEAR: "getTopicsByModelModyear",
    GET_TOPICS_BY_PUBLICATION_GUID: "getTopicsByPublicationGuid",
    GENERATE_ENGINEERED_SOLUTION: "generateEngineeredSolution",
    VIEW_ENGINEERED_SOLUTION: "viewEngineeredSolution",
    DOWNLOAD_ENGINEERED_SOLUTION: "dowloadEngineeredSolution",
};
export const GETTER_TYPES = {
    HAS_ACCESS: "hasAccess"
};
