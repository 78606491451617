export class SelectListVm {
    id;
    name;
    shortnameYear;
    constructor(id, name, shortnameYear = null) {
        this.id = id;
        this.name = name;
        this.shortnameYear = shortnameYear;
    }
    static mapFromBusinessAreaVm(ba) {
        return new SelectListVm(ba.id, ba.fullName);
    }
    static mapFromDivisionVm(division) {
        return new SelectListVm(division.id, division.fullName);
    }
    static mapFromMachineCategoryVm(machineCategory) {
        return new SelectListVm(machineCategory.id, machineCategory.fullName);
    }
    static mapFromMachineCategoryVmForLegalEntity(machineCategory) {
        if (machineCategory === null) {
            return null;
        }
        return new SelectListVm(machineCategory.id, machineCategory.fullName);
    }
    static mapFromProductCompanyVm(productCompany) {
        return new SelectListVm(productCompany.id, productCompany.fullName);
    }
    static mapFromPublicationVm(publication) {
        return new SelectListVm(publication.id, publication.fullName);
    }
    static mapFromBrandVm(brand) {
        return new SelectListVm(brand.id, brand.fullName);
    }
    static mapFromRangeVm(range) {
        return new SelectListVm(range.id, range.fullName);
    }
    static mapFromLegalEntityVm(LegalEntity) {
        return new SelectListVm(LegalEntity.id, LegalEntity.name);
    }
    static mapFromLanguageMappingVm(languageMapping) {
        return new SelectListVm(languageMapping.id, languageMapping.display);
    }
    static mapFromPmnVm(pmn) {
        return new SelectListVm(pmn.id, pmn.label);
    }
    static mapFromModelVm(model) {
        return new SelectListVm(model.id, model.fullName, `${model.shortName} (${model.year})`);
    }
}
