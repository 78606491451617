import { SelectListVm } from "@/models/SelectListVm";
export class EngineeredSolutionVm {
    id;
    projectNumber;
    projectDescription;
    publicationGuid;
    basemodelName;
    featureFeatureValueList;
    serialNumberList;
    languageList;
    canBeDeleted = true;
    constructor({ id, projectNumber, projectDescription, publicationGuid, basemodelName, featureFeatureValueList, serialNumberList, languageList, }) {
        this.id = id;
        this.projectNumber = projectNumber;
        this.projectDescription = projectDescription;
        this.publicationGuid = publicationGuid;
        this.basemodelName = basemodelName;
        this.featureFeatureValueList = featureFeatureValueList;
        this.serialNumberList = serialNumberList;
        this.languageList = languageList;
    }
}
export class EngineeredSolutionAddOrEditVm {
    id;
    projectNumber;
    projectDescription;
    publicationGuid;
    modelId;
    languages;
    featureFeatureValueList;
    serialNumberList;
    languageList;
    topicList;
    _model;
    get model() {
        return this._model;
    }
    set model(v) {
        this._model = v;
        this.modelId = v?.id;
    }
    constructor(engineeredSolution) {
        this.id = engineeredSolution.id;
        this.projectNumber = engineeredSolution.projectNumber;
        this.projectDescription = engineeredSolution.projectDescription;
        this.publicationGuid = engineeredSolution.publicationGuid;
        this._model = SelectListVm.mapFromModelVm(engineeredSolution.model);
        this.modelId = engineeredSolution.model.id;
        this.languages = engineeredSolution.languages;
        this.featureFeatureValueList = engineeredSolution.featureFeatureValueList;
        this.serialNumberList = engineeredSolution.serialNumberList;
        this.languageList = engineeredSolution.languageList;
        this.topicList = engineeredSolution.topicList;
    }
}
