export class PmnVm {
    id;
    value;
    label;
    canBeDeleted = true;
    constructor({ id, value, label, canBeDeleted, }) {
        this.id = id;
        this.value = value;
        this.label = label;
        this.canBeDeleted = canBeDeleted;
    }
}
